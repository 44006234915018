import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  Spinner,
} from "@chakra-ui/react";

import { Lock } from "@mui/icons-material";
import { Fade } from "react-reveal";
// import RM from "../assets/rm.jpg";
// import trust from "../assets/TRUST.PNG";
import RM from "../assets/rm.jpg";

console.log();
export default function QRmodal({ isOpen, onClose }) {
  const toast = useToast();

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent style={{ marginTop: "230px", width: "97%" }}>
          <ModalBody className="mb-3">
            <center>
              <div className=" pb-2 ">
                <h5>
                  ETH ADDRESS (<b>ERC20</b>){" "}
                </h5>
                <p style={{ color: "blue", fontSize: "13px", padding: "10px" }}>
                  Send only ETHEREUM (ERC20) to this address. Sending any other
                  coins may results in permanent loss.
                </p>
                <br />
                <img src={RM} alt="..." className="modalImg " />
              </div>
              <br />
            </center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
